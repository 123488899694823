var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-dialog',{attrs:{"value":_vm.value,"icon":"far fa-calendar-edit","title":"Begegnungsdaten bearbeiten","color":"primary","actions":[ 'close' ]},on:{"close":_vm.close,"esc":_vm.close}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.isMaster)?_c('base-edit-dialog-date-time',{attrs:{"value":_vm.Event.startDate,"id":_vm.id,"label":"Beginn"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'startDate', value, 'DateTime');
}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Beginn","value":_vm._f("dateformat")(_vm.Event.startDate),"readonly":""}})]},proxy:true}],null,false,1644173948)}):_c('v-text-field',{attrs:{"label":"Beginn","value":_vm._f("dateformat")(_vm.Event.startDate),"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.isMaster)?_c('base-edit-dialog-hallensuche',{attrs:{"value":_vm.venue,"id":_vm.id,"label":"Halle","allownew":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'venue', value, 'UUID');
}}}):_c('v-text-field',{attrs:{"label":"Halle","value":_vm.venue,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.isMaster)?_c('base-edit-dialog',{attrs:{"value":_vm.Event.internalComment,"id":_vm.id,"label":"Anmerkungen","textarea":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'internalComment', value);
}}}):_c('v-textarea',{attrs:{"label":"Anmerkungen","value":_vm.Event.internalComment,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.isMaster)?_c('base-edit-dialog',{attrs:{"value":_vm.Event.publicComment,"id":_vm.id,"label":"öffentliche Bemerkungen","textarea":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'publicComment', value);
}}}):_c('v-textarea',{attrs:{"label":"öffentliche Bemerkungen","value":_vm.Event.publicComment,"disabled":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }